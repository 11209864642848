import { App } from '@capacitor/app';
import { Button, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import NotifyIlustration from '@/assets/illustrations/notify-ilustration.svg';
import { Logo, ModalTemplate, Text } from '@/components/elements';
import { useRefreshCurrentUserData } from '@/features/User';
import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';
import { isAndroid, isNative } from '@/utils';

export const ForceUpdateModal = () => {
  const { t } = useAppTranslation();
  const minMobileVersion = useAppSelector((state) => state.user.currentUser?.minMobileVersion);
  const { refreshCurrentUserData } = useRefreshCurrentUserData();

  const [showModal, setShowModal] = useState(false);

  const shouldShowModal = useCallback(async () => {
    const currentVersion = await App.getInfo().then((info) => info.version);

    if (!minMobileVersion || !currentVersion) return;

    const minMobileVersionArr = minMobileVersion.split('.').map(Number);
    const currentVersionArr = currentVersion.split('.').map(Number);

    if (minMobileVersionArr.length !== 3 || currentVersionArr.length !== 3) return;

    if (minMobileVersionArr[0] > currentVersionArr[0]) setShowModal(true);
    if (minMobileVersionArr[0] < currentVersionArr[0]) return;

    if (minMobileVersionArr[1] > currentVersionArr[1]) setShowModal(true);
    if (minMobileVersionArr[1] < currentVersionArr[1]) return;

    if (minMobileVersionArr[2] > currentVersionArr[2]) setShowModal(true);
  }, [minMobileVersion]);

  useEffect(() => {
    if (isNative) shouldShowModal();
  }, [shouldShowModal, minMobileVersion]);

  useEffect(() => {
    if (showModal) refreshCurrentUserData();
  }, [refreshCurrentUserData, showModal]);

  const openStore = () => {
    // TODO: Replace with actual app store links
    if (isAndroid) window.open('https://play.google.com/store/apps/details?id=com.refereewise.app', '_system');
    else window.open('https://apps.apple.com/app/refereewise-referee-tests/id6736403214', '_system');
  };

  return (
    <ModalTemplate fullScreen isOpen={showModal}>
      <Stack spacing={20} justifyContent="center" alignItems="center" height="100%">
        <Logo size="large" />
        <Stack spacing={8} justifyContent="space-between" alignItems="center">
          <NotifyIlustration height={150} width={250} />
          <Text variant="headlineMedium" translationKey="common:timeForUpdate" />
          <Text textAlign="center" variant="bodyMedium" translationKey="common:timeForUpdateDescription" />
          <Button variant="contained" onClick={openStore}>
            {t('common:updateNow')}
          </Button>
        </Stack>
      </Stack>
    </ModalTemplate>
  );
};
